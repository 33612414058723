import React from 'react';
import Day from './Day';
import './BookingCalendar.css'

const DayNames = () => (
    <div className='week names'>
        <Day>Mo</Day>
        <Day>Di</Day>
        <Day>Mi</Day>
        <Day>Do</Day>
        <Day>Fr</Day>
        <Day>Sa</Day>
        <Day>So</Day>
    </div>
);

export default DayNames;