import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {NavbarLight} from "../NavbarLight";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import withStyles from "@material-ui/core/styles/withStyles";
import {indigo} from '@material-ui/core/colors';
import Carousel from "./Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../Footer/Footer";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Divider} from "@material-ui/core";
import {Helmet} from "react-helmet";
import CookieConsent from "react-cookie-consent";
import ReactPlayer from 'react-player';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#434A7E",
        '&:hover': {
            backgroundColor: indigo[200],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
    },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
    root: {
        color: "#434A7E",
        backgroundColor: "#fff",
        '&:hover': {
            backgroundColor: indigo[200],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
    },
    cardTextGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#434A7E',
        marginBottom: theme.spacing(2),
    },
    main2: {
        backgroundColor: '#e3e6ff',
        marginBottom: theme.spacing(2),
    },
    footer: {
        backgroundColor: '#343A40',
        marginTop: theme.spacing(8),
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",
    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    }
}));

export default function Album() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ferienwohnung Krakvitz Rügen - Ökologische Ferien auf der Insel Rügen </title>
                <meta
                    name="description"
                    content=
                        "Ökologische Ferien auf Rügen: In unseren Ferienwohnung Vilm im Dorf Krakvitz auf Rügen.
                        Genießen sie einen ruhigen Urlaub an der Ostsee. In der Natur und fernab vom Massentourismus in unserer Ferienwohnung auf Rügen.
                        Perfekt für Erwachsene und Familien mit Kindern."
                />
                <meta
                    name="keywords"
                    content=
                        "rügen, ferienwohnung, ferienwohnungen, insel rügen, ferienwohnung rügen, ferienwohnung krakvitz,
                         ferienwohnung ostsee, ostsee, personen, sommer, strand, ferienwohnung mieten, krakvitz, putbus, ferienwohnung putbus, ferienwohnung kinder, kinder,
                         ferienwohnung mieten rügen, ferienwohnung mieten putbus, ferienwohnung mieten ostsee, kinderfreundlich, fewo rügen, fewo ostsee, fewo putbus, fewo krakvitz, fewo,
                         ökologisch, naturnah, Vilm, Ökoferien, Ökourlaub, Nichtraucher, Ökourlaub Ostsee, Ferienhaus, Ferienhaus Ostsee, Ferienhaus Rügen, Bio Urlaub"
                />
                <link rel="canonical" href="https://xn--waschhaus-rgen-qsb.de/" />

            </Helmet>

            <ThemeProvider theme={theme}>

            <main className={classes.main1}>
                <NavbarLight/>

                    <Container disableGutters={true} maxWidth="lg">
                        <Typography variant="h2" component="h1" gutterBottom className={classes.header} align="center" marginTop={5}>
                            Ferienwohnung Krakvitz Rügen
                        </Typography>
                    </Container>

                <Container className={classes.main2} maxWidth="lg" disableGutters={true}>

                    <Carousel />

                <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h3" component="h2" gutterBottom className={classes.text1} align="center">
                                Herzlich Willkommen!
                            </Typography>
                            <Typography variant="h4" component="h3" gutterBottom className={classes.text1} align="center">
                                In unseren Ferienwohnungen im UNESCO Biosphärenreservat Südost-Rügen
                            </Typography>

                            <Container className={classes.heroButtons} maxWidth="xl" justify="center">
                                <ReactPlayer url="https://krakvitz.firebaseapp.com/images/haus/2.mp4" width="100%" height="100%" loop={"true"} playing ={"true"} controls={"true"}	/>
                            </Container>


                            <Typography variant="body1" component="body1" gutterBottom className={classes.text2} align="center">
                                <br/>
                                Das idyllische Dorf Krakvitz auf Rügen liegt auf halbem Weg zwischen der Ostsee und der alten Residenzstadt Putbus im Biosphärenreservat Südost-Rügen.
                                Die Geschichte des Dorfes reicht weit bis in die Schwedenzeit zurück und überrascht heute wie damals durch sein geschlossenes, landschaftstypisches Ensemble mit reetgedeckten, alten Häusern.
                                Dazu gehört seit 2006 auch unser baubiologisch errichtetes, reetgedecktes Haupthaus und das rennovierte, historische Waschhaus des Dorfes.
                                Das Haupthaus wurde im Stil einer alten Scheune gebaut und vielen für Rügen typischen Merkmalen nachempfunden.
                                Von der Giebelansicht mit großen Rundbogenfenstern bis hin zum Reetdach passt sich das Haus in die historische Bebauung des Ortes ein.
                                Dem historischen Vorbild entsprechend gestaltete Katenfenster betonen das harmonische Gefüge von Haus und Natur.
                                Im großen, landschaftlich reizvollen, schön eingewachsenen Garten mit mehreren Sitzecken finden Sie Ruhe und Spielmöglichkeiten für Ihre Kinder (u. a. Schaukel, Tischtennisplatte, Trampolin).
                                <br/>
                                <br/>

                            </Typography>

                            <Container className={classes.heroButtons} maxWidth="sm" justify="center">
                                <img src="https://krakvitz.firebaseapp.com/images/rügen/RügenB.jpg" width="100%" height="100%"/>
                            </Container>


                            <Divider variant= "middle"/>

                            <Typography variant="body1" component="body1" gutterBottom className={classes.text2} align="center">
                                <br/>
                                In der ersten Etage des neu gebauten Reethauses finden Sie heute die ökologische Ferienwohnung "Vilm".
                                Auf 65 qm unter Reet können Sie den weiten Landschaftsblick aus der inseltypischen Gaube nach Süden aus dem Wohnzimmer mit Kamin und Komfort-Ausstattung genießen.
                                Zwei geräumige Schlafzimmer mit je einem Doppelbett bieten bis zu 4 Personen einen komfortabelen Aufenthalt.
                                Das Bad mit Fenster, Dusche und gemütlicher Badewanne lädt auch in der kalten Jahreszeit zu einem erholsamen Vollbad ein.
                                <br/> <br/>
                                Das Haus mit der Ferienwohnung "Vilm" wurde nach baubiologischen Richtlinien erbaut.
                                Alle Fußböden bestehen allergikerfreundlich aus Mamor oder Eichenholzdielen (Vollholz), die Wände wurden aus Lehmziegeln und Lehmbauplatten errichtet und verputzt.
                                Lehmfarben verschaffen allen Räumen einen individuellen, warmen Charakter.
                                Elektroleitungen sind abgeschirmt verlegt worden und die Wasserleitungen bestehen überwiegend aus Edelstahl.
                                Das Wohnklima ist durch die Lehmbauweise auch im Sommer sehr angenehm, da Wärme und Kälte gleichermaßen abgehalten wird, auch im Dachgeschoss.
                                Aus diesem Grunde ist der Handyempfang im ganzen Haus durch die Abschirmung der Wände kaum möglich.
                                <br/> <br/>
                            </Typography>

                            <Container className={classes.heroButtons} maxWidth="sm" justify="center">
                            <img src="https://krakvitz.firebaseapp.com/images/vilm/VilmB.jpg" width="100%" height="100%"/>
                            </Container>

                            <Container className={classes.heroButtons} maxWidth="md">
                                <Grid container justify="center">

                                    <ButtonGroup enableElevation  variant="contained" size="large" color="primary">
                                        <ColorButton href="/fewo-vilm" >
                                            Zur FeWo "Vilm"
                                        </ColorButton>
                                    </ButtonGroup>
                                </Grid>
                            </Container>
                            <br/>

                            <Divider variant= "middle"/>

                            <Typography variant="body1" component="body1" gutterBottom className={classes.text2} align="center">
                                <br/>
                                Entspannen Sie bei ausgedehnten Spaziergängen durch die Umgebung mit Blick auf die Ostsee und erholen Sie sich in unseren großzügigen, komfortabel eingerichteten Ferienwohnungen.
                                Nicht nur Reitfreunde kommen in der Umgebung im Reiterhof Altkamp auf Ihre Kosten; Segler können im nahegelegenen Jachthafen Lauterbach ihren Törn starten.
                                <br/>
                                Der schönste Golfplatz der Insel liegt nur etwa 5 km entfernt am Schloß Karnitz und lädt mit einem 18-Loch Turnier-Platz (PAR 72, Platzreife erforderlich) sowie einem öffentlichen 9-Loch-Platz für jedermann zu einem Besuch ein.
                                <br/>
                                Erkunden Sie zu Fuß oder mit dem Fahrrad die wunderschönen Alleen und wandeln Sie auf den Spuren des Dichers Ernst Moritz Arndt, der unweit in der ältesten Stadt Rügens Garz geboren wurde.
                                <br/>
                                Geniessen Sie selbst in der Hauptsaison die Ruhe der ländlichen Umgebung fernab vom Trubel der bekannten Touristenhochburgen und entdecken Sie zu Fuß, per Rad oder mit dem Auto die Reize der naturnahen Landschaft.
                                In nur etwa 5 Minuten mit dem Rad von dem Ort Krakvitz aus finden Sie einsame Naturstrände an der Ostsee mit prächtigem Blick auf die vorgelagerte Insel Vilm.
                                <br/> <br/>
                            </Typography>

                            <Container className={classes.heroButtons} maxWidth="sm" justify="center">
                                <img src="https://krakvitz.firebaseapp.com/images/rügen/RügenA.jpg" width="100%" height="100%"/>
                            </Container>


                            <Container className={classes.heroButtons} maxWidth="md">
                                <Grid container justify="center">
                                    <ButtonGroup enableElevation  variant="contained" size="large" color="primary">
                                        <ColorButton href="/aktivitaeten" >
                                            Mehr Aktivitäten
                                        </ColorButton>
                                    </ButtonGroup>
                                </Grid>
                            </Container>
                            <br/>

                            <Divider variant= "middle"/>

                        </CardContent>
                    </Container>

                <Container className={classes.cardGrid}  maxWidth="md">
                    <Grid container spacing={4}>
                            <Grid item xs={12} sm={8} md={6}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image="https://krakvitz.firebaseapp.com/images/haus/HausA.jpg"
                                        title="Image title"
                                    />

                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h4" className={classes.text2}>
                                            Ferienwohnung "Vilm"
                                        </Typography>
                                        <Typography component="body1" className={classes.text2}>
                                            Im Obergeschoss des 2006 nach baubiogischen Richtlininien erbauten Haupthauses liegt die Ferienwohnung "Vilm".
                                            Auf 65qm können Sie hier mit bis zu 4 Personen ein inseltypisches Wohngefühl unter traditionellem Reetdach erleben.
                                        </Typography>
                                    </CardContent>
                                    <div className={classes.heroButtons}>
                                        <Grid container justify="center">
                                            <ButtonGroup enableElevation variant="contained"size="large">
                                                <ColorButton2 href="/fewo-vilm">
                                                    Mehr Informationen
                                                </ColorButton2>
                                                <ColorButton href="/kontakt">
                                                    Buchungsanfrage
                                                </ColorButton>
                                            </ButtonGroup>
                                        </Grid>
                                    </div>
                                </Card>
                            </Grid>


                    </Grid>
                </Container>
                </Container>
            </main>
            <Footer/>
            </ThemeProvider>
        </React.Fragment>
    );
}
