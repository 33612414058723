import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Buchungsformular from "./Buchungsformular";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {NavbarKontakt} from "./NavbarKontakt";
import Footer from "../Footer/Footer";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import {Helmet} from "react-helmet";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },

    main1: {
        backgroundColor: '#434A7E',
    },
    main2: {
        backgroundColor: '#e3e6ff',
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",

    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        marginTop: theme.spacing(4),
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text3: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
        fontWeight: "bold",
    }
}));

export default function Album() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ferienwohnungen Waschhaus Rügen - Buchungsanfrage </title>
                    <meta
                        name="description"
                        content=
                            "Ökologische Ferien auf Rügen: In unseren Ferienwohnungen Altes Waschhaus und Vilm im Dorf Krakvitz auf Rügen.
                        Genießen sie einen ruhigen Urlaub an der Ostsee. In der Natur und fernab vom Massentourismus in unseren Ferienwohnungen auf Rügen.
                        Perfekt für Erwachsene und Familien mit Kindern."
                    />
                    <meta
                        name="keywords"
                        content=
                            "altes waschhaus rügen, altes waschhaus, rügen, ferienwohnung, ferienwohnungen, insel rügen, ferienwohnung rügen, ferienwohnung krakvitz,
                         ferienwohnung ostsee, ostsee, personen, sommer, strand, ferienwohnung mieten, krakvitz, putbus, ferienwohnung putbus, ferienwohnung kinder, kinder,
                         ferienwohnung mieten rügen, ferienwohnung mieten putbus, ferienwohnung mieten ostsee, kinderfreundlich, fewo rügen, fewo ostsee, fewo putbus, fewo krakvitz, fewo,
                         ökologisch, naturnah, Vilm, Ökoferien, Ökourlaub, Nichtraucher, Ökourlaub Ostsee, Ferienhaus, Ferienhaus Ostsee, Ferienhaus Rügen, Bio Urlaub"
                    />
                    <link rel="canonical" href="https://xn--waschhaus-rgen-qsb.de/kontakt" />

                </Helmet>

            <main className={classes.main1}>
                <NavbarKontakt/>

                <Container maxWidth="lg" >
                    <Typography variant="h2" component="h2" gutterBottom className={classes.header} align="center">
                        Buchungsanfrage
                    </Typography>
                </Container>

                <Container maxWidth="md">
                    <Grid>
                        <Card>
                            <CardContent className={classes.cardContent}>
                            </CardContent>
                            <Buchungsformular/>

                            <Container maxWidth="sm" >
                                <Typography className={classes.text3} variant="caption" display="block" gutterBottom align="center" > * Mit dem Abschicken der Anfrage erklären Sie Ihr Einverstädnnis mit unseren  Datenschutzerklärung  zur Verarbeitung von personenbezogenen Daten. </Typography>
                                <Link   href="/datenschutzerklaerung" variant="caption" display="block" gutterBottom align="center">
                                    Datenschutzerklärung hier abrufbar
                                </Link>
                            </Container>
                        </Card>
                    </Grid>

                </Container>
            </main>
            <Footer/>
            </ThemeProvider>

        </React.Fragment>
    );
}
