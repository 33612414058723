import React, { Component } from 'react'
import { useState } from "react";

import * as emailjs from 'emailjs-com'
import { Form } from 'reactstrap'
import { withStyles } from '@material-ui/core/styles';
import { FormGroup } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {indigo} from "@material-ui/core/colors";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#434A7E",
        '&:hover': {
            backgroundColor: indigo[200],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
    },
}))(Button);

const styles = {
    root: {
        '& .MuiTextField-root': {
            margin: "10px",
            width: '100ch',
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "10px",
        marginBottom: "10px",
        width: "90%",
    },
    messageField: {
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "10px",
        marginBottom: "10px",
        width: "90%",
    },
    heroButtons: {
        marginLeft: "25%",
        marginRight: "25%",
        marginTop: "10px",
        marginBottom: "10px",
        width: "50%",

    },
};



class Buchungsformular extends Component {
    state = {
        email: '',
        telefon: '',
        subject: '',
        message: '',
        ferienwohnung: '',
        anfahrt: '',
        abfahrt: '',
        kinder: '',
        erwachsene: '',
        vorname: '',
        nachname: '',
        quelle: '',
    }



    handleSubmit(e) {
        e.preventDefault()
        const { email, telefon, subject, message, ferienwohnung, anfahrt, abfahrt, kinder, erwachsene, vorname, nachname, quelle } = this.state
        let templateParams = {
            email: email,
            telefon: telefon,
            to_name: 'Altes Waschhaus',
            subject: subject,
            message_html: message,
            ferienwohnung: ferienwohnung,
            anfahrt: anfahrt,
            abfahrt: abfahrt,
            kinder: kinder,
            erwachsene: erwachsene,
            vorname: vorname,
            nachname: nachname,
            quelle: quelle,
            redirectToReferrer: false

        }
        emailjs.send(
            'gmail',
            'template_4CvV5FV9',
            templateParams,
            'user_wAhmfmSiEivROjdwXA3Ls'
        )
        this.resetForm()
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    resetForm() {
        this.setState({
            email: 'Danke für Ihre Anfrage!',
            telefon: 'Danke für Ihre Anfrage!',
            message: 'Danke für Ihre Anfrage!',
            ferienwohnung: 'Danke für Ihre Anfrage!',
            anfahrt: '',
            abfahrt: '',
            kinder: '',
            erwachsene: '',
            vorname: 'Danke für Ihre Anfrage!',
            nachname: 'Danke für Ihre Anfrage!',
            quelle: 'Danke für Ihre Anfrage!',
            redirectToReferrer: true
        })
    }


    render() {
        const { classes } = this.props; //<----- grab classes here in the props
        // const redirectToReferrer = this.state.redirectToReferrer;
        // if (redirectToReferrer === true) {
        //    window.location = 'https://xn--waschhaus-rgen-qsb.de/buchungerfolgreich';
        // }

        return (
            <div>
                <Form href="fewo-vilm" onSubmit={this.handleSubmit.bind(this)}>
                    <div>
                        <FormGroup controlId="formBasicName" >
                            <TextField
                                required
                                id="outlined-required"
                                label="Vorname"
                                defaultValue=" "
                                variant="outlined"
                                name="vorname"
                                className={classes.textField}
                                value={this.state.vorname}
                                onChange={this.handleChange.bind(this, 'vorname')}
                            />
                        </FormGroup>
                        <FormGroup controlId="formBasicName">
                            <TextField
                                required
                                id="outlined-required"
                                label="Nachname"
                                defaultValue=" "
                                variant="outlined"
                                name="nachname"
                                className={classes.textField}
                                value={this.state.nachname}
                                onChange={this.handleChange.bind(this, 'nachname')}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup controlId="formBasicEmail">
                        <TextField
                            required
                            id="outlined-required"
                            label="E-Mail"
                            defaultValue=" "
                            variant="outlined"
                            name="email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange.bind(this, 'email')}
                        />
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                        <TextField
                            required
                            id="outlined-required"
                            label="Telefon"
                            defaultValue=" "
                            variant="outlined"
                            name="telefon"
                            className={classes.textField}
                            value={this.state.telefon}
                            onChange={this.handleChange.bind(this, 'telefon')}
                        />
                    </FormGroup>
                    <div>
                        <FormGroup controlId="formBasicErwachsene">
                            <TextField
                                required
                                id="outlined-number"
                                label="Erwachsene"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.erwachsene}
                                onChange={this.handleChange.bind(this, 'erwachsene')}

                            />
                        </FormGroup>
                        <FormGroup controlId="formBasicKinder">
                            <TextField
                                id="outlined-number"
                                label="Kinder (0-14 Jahre)"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.textField}
                                variant="outlined"
                                value={this.state.kinder}
                                onChange={this.handleChange.bind(this, 'kinder')}

                            />
                        </FormGroup>
                        <FormGroup controlId="formBasicEmail">
                            <TextField
                                required
                                id="date"
                                label="Anreise"
                                type="date"
                                defaultValue="2020-06-01"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={this.state.anfahrt}
                                onChange={this.handleChange.bind(this, 'anfahrt')}
                            />
                        </FormGroup>
                        <FormGroup controlId="formBasicEmail">
                            <TextField
                                required
                                id="date"
                                label="Abfahrt"
                                type="date"
                                defaultValue="2020-06-01"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={this.state.abfahrt}
                                onChange={this.handleChange.bind(this, 'abfahrt')}
                            />
                        </FormGroup>
                        <FormGroup className={classes.textField} controlId="formBasicMessage">
                            <TextField
                                id="outlined-multiline-static"
                                label="Ihre Nachricht"
                                multiline
                                rows={10}
                                defaultValue=" "
                                variant="outlined"
                                name="message"
                                value={this.state.message}
                                onChange={this.handleChange.bind(this, 'message')}
                            />
                            <FormHelperText>Ihre Wünsche und Fragen an uns.</FormHelperText>
                        </FormGroup>
                        <FormGroup className={classes.textField} controlId="formBasicMessage">
                            <TextField
                                id="outlined-multiline-static"
                                label="Wie sind Sie auf uns aufmerksam geworden?"
                                multiline
                                rows={1}
                                defaultValue=" "
                                variant="outlined"
                                name="message"
                                value={this.state.quelle}
                                onChange={this.handleChange.bind(this, 'quelle')}
                            />
                        </FormGroup>
                    </div >

                    <div className={classes.root}>
                    <ColorButton className={classes.heroButtons} type="submit" value="Send" variant="contained" color="primary" size="large">
                        Anfrage abschicken*
                    </ColorButton>

                        </div>

                </Form>
            </div>
        )
    }
}
export default withStyles(styles)(Buchungsformular)

