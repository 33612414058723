import React from "react";
import { Carousel } from "react-responsive-carousel";

export default () => (
    <Carousel autoPlay>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/vilm/VilmB.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/haus/HausC.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/vilm/VilmD.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/garten/GartenC.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/vilm/VilmE.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/vilm/VilmF.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/garten/GartenD.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/vilm/VilmA.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/rügen/RügenA.jpg" />
        </div>
    </Carousel>
);
