import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayNames from '../Calendar/DayNames';
import Week from '../Calendar/Week';
import '../Calendar/BookingCalendar.css'

moment.locale("de",{months:"Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"),monthsShort:"Jan._Febr._Mrz._Apr._Mai_Jun._Jul._Aug._Sept._Okt._Nov._Dez.".split("_"),monthsParseExact:!0,weekdays:"Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"),weekdaysShort:"So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),weekdaysMin:"So_Mo_Di_Mi_Do_Fr_Sa".split("_"),weekdaysParseExact:!0,longDateFormat:{LT:"HH:mm",LTS:"HH:mm:ss",L:"DD.MM.YYYY",LL:"D. MMMM YYYY",LLL:"D. MMMM YYYY HH:mm",LLLL:"dddd, D. MMMM YYYY HH:mm"},calendar:{sameDay:"[heute um] LT [Uhr]",sameElse:"L",nextDay:"[morgen um] LT [Uhr]",nextWeek:"dddd [um] LT [Uhr]",lastDay:"[gestern um] LT [Uhr]",lastWeek:"[letzten] dddd [um] LT [Uhr]"},   relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },

    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
// In case the meridiem units are not separated around 12, then implement
// this function (look at locale/id.js for an example).
// meridiemHour : function (hour, meridiem) {
//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
// },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

export default class VilmBookingCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            previousDisabled: this.props.disableHistory,
            month: props.selected.clone(),
            selected: props.selected,
        };
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    updatePreviousState() {
        if (this.props.disableHistory) {
            const previousDisabled = this.state.month.isSame(moment(), 'month') && this.state.month.isSame(moment(), 'year');
            this.setState({ previousDisabled });
        }
    }

    handlePrevious() {
        const { month } = this.state;
        month.subtract(1, 'M');
        this.setState({ month });
        this.updatePreviousState();
    }

    handleNext() {
        const { month } = this.state;
        month.add(1, 'M');
        this.setState({ month });
        this.updatePreviousState();
    }

    handleSelect(day) {
        if (this.props.clickable) {
            this.setState({ selected: day.date });
        }
    }

    renderMonthLabel() {
        return (
            <span className='month-label'>
        {this.state.month.format('MMMM, YYYY')}
      </span>
        );
    }

    renderWeeks() {
        const weeks = [];
        let done = false;
        const date = this.state.month.clone().startOf('month').add('w' - 1).startOf('isoWeek');
        let monthIndex = date.month();
        let count = 0;

        while (!done) {
            weeks.push(<Week
                bookings={this.props.bookings}
                clickable={this.props.clickable}
                date={date.clone()}
                key={date.toString()}
                month={this.state.month}
                selected={this.state.selected}
                selectHandler={this.handleSelect}
            />);
            date.add(1, 'w');
            done = count > 2 && monthIndex !== date.month();
            count += 1;
            monthIndex = date.month();
        }

        return weeks;
    }

    render() {
        return (
            <div className='booking-calendar'>
                <div className='header'>
                    <div className='header-content'>
                        {this.renderMonthLabel()}
                        <button className='icon-previous' disabled={this.state.previousDisabled} onClick={this.handlePrevious}>
                            &lt;
                        </button>
                        <button className='icon-next' onClick={this.handleNext}>&gt;</button>
                    </div>
                </div>
                <DayNames />
                {this.renderWeeks()}
            </div>
        );
    }

}

VilmBookingCalendar.propTypes = {
    bookings: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    clickable: PropTypes.bool,
    disableHistory: PropTypes.bool,
    selected: PropTypes.instanceOf(moment),
};

VilmBookingCalendar.defaultProps = {
    bookings: [
        new Date(2021, 12-1, 30),
        new Date(2021, 12-1, 31),
        new Date(2022, 1-1, 1),
        new Date(2022, 1-1, 2),
        new Date(2022, 1-1, 3),
        new Date(2022, 1-1, 4),
        new Date(2022, 1-1, 5),
        new Date(2022, 1-1, 6),
        new Date(2022, 1-1, 7),
        new Date(2022, 1-1, 8),
        new Date(2022, 1-1, 9),
        new Date(2022, 1-1, 10),
        new Date(2022, 1-1, 11),

        new Date(2022, 4-1, 15),
        new Date(2022, 4-1, 16),
        new Date(2022, 4-1, 17),
        new Date(2022, 4-1, 18),
        new Date(2022, 4-1, 19),
        new Date(2022, 4-1, 20),
        new Date(2022, 4-1, 21),
        new Date(2022, 4-1, 22),

        //new Date(2022, 4-1, 28),
        //new Date(2022, 4-1, 29),
        //new Date(2022, 4-1, 30),
        //new Date(2022, 5-1, 1),
        //new Date(2022, 5-1, 2),
        //new Date(2022, 5-1, 3),
        //new Date(2022, 5-1, 4),

        new Date(2022, 7-1, 9),
        new Date(2022, 7-1, 10),
        new Date(2022, 7-1, 11),
        new Date(2022, 7-1, 12),
        new Date(2022, 7-1, 13),
        new Date(2022, 7-1, 14),
        new Date(2022, 7-1, 15),

        new Date(2022, 7-1, 16),
        new Date(2022, 7-1, 17),
        new Date(2022, 7-1, 18),
        new Date(2022, 7-1, 19),
        new Date(2022, 7-1, 20),
        new Date(2022, 7-1, 21),
        new Date(2022, 7-1, 22),
        new Date(2022, 7-1, 23),
        new Date(2022, 7-1, 24),
        new Date(2022, 7-1, 25),
        new Date(2022, 7-1, 26),
        new Date(2022, 7-1, 27),
        new Date(2022, 7-1, 28),
        new Date(2022, 7-1, 29),

        new Date(2022, 9-1, 3),
        new Date(2022, 9-1, 4),
        new Date(2022, 9-1, 5),
        new Date(2022, 9-1, 6),
        new Date(2022, 9-1, 7),
        new Date(2022, 9-1, 8),
        new Date(2022, 9-1, 9),
        new Date(2022, 9-1, 10),
        new Date(2022, 9-1, 11),
        new Date(2022, 9-1, 12),
        new Date(2022, 9-1, 13),
        new Date(2022, 9-1, 14),
        new Date(2022, 9-1, 15)

    ],
    clickable: true,
    disableHistory: true,
    selected: moment().startOf('day'),
};
