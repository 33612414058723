import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Carousel from "./Carousel";
import VilmBookingCalendar from "./VilmBookingCalendar";
import {NavbarVilm} from "./NavbarVilm";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import KitchenIcon from '@material-ui/icons/Kitchen';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import DeckIcon from '@material-ui/icons/Deck';
import {blue, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import ErrorIcon from "@material-ui/icons/Error";
import Footer from "../Footer/Footer";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#434A7E",
        '&:hover': {
            backgroundColor: green[700],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',

    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },

    main1: {
        backgroundColor: '#434A7E',
    },
    main2: {
        backgroundColor: '#e3e6ff',
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",

    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        marginTop: theme.spacing(4),
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text3: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
        fontWeight: "bold",
    }
}));

export default function FewoVilm() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Waschhaus Rügen - Ferienwohnung "Vilm" </title>
                <meta
                    name="description"
                    content=
                        "Ökologische Ferien auf Rügen: In unseren Ferienwohnungen Altes Waschhaus und Vilm im Dorf Krakvitz auf Rügen.
                        Genießen sie einen ruhigen Urlaub an der Ostsee. In der Natur und fernab vom Massentourismus in unseren Ferienwohnungen auf Rügen.
                        Perfekt für Erwachsene und Familien mit Kindern."
                />
                <meta
                    name="keywords"
                    content=
                        "altes waschhaus rügen, altes waschhaus, rügen, ferienwohnung, ferienwohnungen, insel rügen, ferienwohnung rügen, ferienwohnung krakvitz,
                         ferienwohnung ostsee, ostsee, personen, sommer, strand, ferienwohnung mieten, krakvitz, putbus, ferienwohnung putbus, ferienwohnung kinder, kinder,
                         ferienwohnung mieten rügen, ferienwohnung mieten putbus, ferienwohnung mieten ostsee, kinderfreundlich, fewo rügen, fewo ostsee, fewo putbus, fewo krakvitz, fewo,
                         ökologisch, naturnah, Vilm, Ökoferien, Ökourlaub, Nichtraucher, Ökourlaub Ostsee, Ferienhaus, Ferienhaus Ostsee, Ferienhaus Rügen, Bio Urlaub"
                />
                <link rel="canonical" href="https://xn--waschhaus-rgen-qsb.de/fewo-vilm" />

            </Helmet>
            <ThemeProvider theme={theme}>
            <main className={classes.main1}>
                <NavbarVilm/>
                <Container disableGutters={true} maxWidth="100%">
                    <Typography variant="h2" component="h1" gutterBottom className={classes.header} align="center">
                        Ferienwohnung "Vilm"
                    </Typography>
                </Container>
                <Container className={classes.main2} maxWidth="lg" disableGutters={true}>

                    <Carousel />

                    <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h3" component="h2" gutterBottom className={classes.text1} align="center">
                                Ökologisch Wohnen auf der Insel Rügen
                            </Typography>
                            <Typography variant="body1" component="body1" gutterBottom className={classes.text2} align="center">
                                Im ersten Obergeschoß des 2005 neu erbauten Hauses wohnen Sie auf 65 qm unter Reet. Geniessen Sie den weiten Landschaftsblick aus der inseltypischen Gaube nach Süden aus dem Wohnzimmer mit Kamin und 4-Sterne-Komfort-Ausstattung. Zwei geräumige Schlafzimmer mit je einem Doppelbett bieten bis zu 4 Personen einen komfortabelen Aufenthalt. Das Bad mit Fenster, Dusche und gemütlicher Badewanne lädt auch in der kalten Jahreszeit zu einem erholsamen Vollbad ein.
                                Das Haus mit den Wohnungen "Vilm" und "Putbus" wurde nach baubiologischen Richtlinien erbaut. Alle Fußböden bestehen allergikerfreundlich aus Mamor, die Wände wurden aus Lehmziegeln und Lehmbauplatten errichtet und verputzt. Lehmfarben verschaffen allen Räumen einen individuellen, warmen Charakter. Elektroleitungen sind abgeschirmt verlegt worden und die Wasserleitungen bestehen überwiegend aus Edelstahl.
                                Das Wohnklima ist durch die Lehmbauweise auch im Sommer sehr angenehm, da Wärme und Kälte gleichermaßen abgehalten wird, auch im Dachgeschoss. Aus diesem Grunde ist der Handyempfang im ganzen Haus durch die Abschirmung der Wände kaum möglich.
                            </Typography>
                        </CardContent>
                </Container>

                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Card className={classes.main2} variant="outlined">
                                <CardContent className={classes.cardContent} >
                                    <Typography variant="h3" component="h3" gutterBottom className={classes.text1} align="center">
                                        Ausstattung
                                    </Typography>
                                    <List className={classes.root} >
                                        <ListItem >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <LocalHotelIcon style={{color:grey[900]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Zur Wohnung</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>65qm Wohnraum, 2 Schlafzimmer, max. 4 Personen, Marmorboden, WLAN, Dachgaube mit Südblick </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BathtubIcon style={{color:blue[700]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Badezimmer</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Dusche, Badewanne, Föhn, Handtücher </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <FireplaceIcon style={{color:red[700]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Wohnzimmer</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Fernseher, Gemütlicher Kaminofen, Sofa, Stereoanlage, Grozügiger Essbereich </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <KitchenIcon style={{color:orange[700]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Küche</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Ceranfeld, Geschirrspüler, Mikrowelle, Kühlschrank, Kaffeemaschine, Wasserkocher, Toaster </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <DeckIcon style={{color:green[700]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Garten</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Sehr großer Garten, Sitzgelegenheiten mit Strandkorb, Grillplatz, Tischtennis, Trampolin, Schaukel </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BeachAccessIcon style={{color:yellow[700]}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Aktivitäten</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Fußläufiger Naturstrand, Wandern, Reiterhof </Typography>}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ErrorIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography component="h4" className={classes.text3} fontWeight="bold" type="body1" style={{ color: '#434A7E' }}>Sonstige Informationen</Typography>}
                                                secondary={<Typography component="body1" className={classes.text1} type="body2" style={{ color: '#434A7E' }}>Nichtraucher, keine Haustiere </Typography>}
                                            />
                                        </ListItem>
                                    </List>

                                </CardContent>
                            </Card>
                        </Grid>
                        {/*        <Grid item xs={12} sm={6} md={6}>
                            <Card className={classes.main2} variant="outlined" alignItems="center"
                                  justifyContent="center">
                                <CardContent className={classes.cardContent} >
                                    <Typography variant="h4" gutterBottom className={classes.text1} align="center">
                                        Buchungskalender "Vilm"
                                    </Typography>
                                </CardContent>
                                <VilmBookingCalendar/>
                            </Card>
                        </Grid>
                        <Grid className={classes.cardGrid} maxWidth="md">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Card className={classes.main2} variant="outlined">

                            <ButtonGroup enableElevation  variant="contained" size="large" color="primary" alignItems="center" justifyContent="center">
                                <ColorButton href="/kontakt" >
                                    Buchungsanfrage
                                </ColorButton>
                            </ButtonGroup>

                                        </Card>
                                    </Grid>
                                </Grid>
                         </Grid>
                        */}
                    </Grid>
                </Container>
                </Container>
            </main>
                <Footer/>
            </ThemeProvider>

        </React.Fragment>
    );
}
