import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TitlebarGridList from "./Gridlist";
import {NavbarAtivitaeten} from "./NavBarAktivitaeten";
import Typography from "@material-ui/core/Typography";
import Footer from "../Footer/Footer";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#434A7E',
    },
    main2: {
        backgroundColor: '#e3e6ff',
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",

    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        marginTop: theme.spacing(4),
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text3: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
        fontWeight: "bold",
    }
}));

export default function Aktivitaeten() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>

            <main className={classes.main1}>
                <NavbarAtivitaeten/>

                    <Container disableGutters={true}>
                        <Typography variant="h2" component="h1" gutterBottom className={classes.header} align="center" marginTop={5}>
                            Aktivitäten auf Rügen
                        </Typography>
                    </Container>

                <TitlebarGridList/>

        </main>
            <Footer/>
            </ThemeProvider>

        </React.Fragment>
    );
}
