import React from "react";
import { Carousel } from "react-responsive-carousel";

export default () => (
    <Carousel autoPlay>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/haus/HausA.jpg" />
        </div>

        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/garten/GartenA.jpg" />
        </div>
        <div>
                <img alt="" src="https://krakvitz.firebaseapp.com/images/haus/HausB.jpg" />
            </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/rügen/RügenA.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/haus/HausD.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/rügen/RügenB.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/garten/GartenD.jpg" />
        </div>
        <div>
            <img alt="" src="https://krakvitz.firebaseapp.com/images/haus/HausC.jpg" />
        </div>
    </Carousel>
);
