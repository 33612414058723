import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import {NavbarPreiseundBelgungsplan} from "./NavbarPreiseundBelgungsplan";
import Preise from "./Preise";
import Belegungsplan from "./Belegungsplan";
import Footer from "../Footer/Footer";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import CardContent from "@material-ui/core/CardContent";
import {Helmet} from "react-helmet";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#434A7E',
    },
    main2: {
        backgroundColor: '#e3e6ff',
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",

    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        marginTop: theme.spacing(4),
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text3: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
        fontWeight: "bold",
    }
}));

export default function Aktivitaeten() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ferienwohnungen Waschhaus Rügen - Preise & Belegungsplan </title>
                <meta
                    name="description"
                    content=
                        "Ökologische Ferien auf Rügen: In unseren Ferienwohnungen Altes Waschhaus und Vilm im Dorf Krakvitz auf Rügen.
                        Genießen sie einen ruhigen Urlaub an der Ostsee. In der Natur und fernab vom Massentourismus in unseren Ferienwohnungen auf Rügen.
                        Perfekt für Erwachsene und Familien mit Kindern."
                />
                <meta
                    name="keywords"
                    content=
                        "altes waschhaus rügen, altes waschhaus, rügen, ferienwohnung, ferienwohnungen, insel rügen, ferienwohnung rügen, ferienwohnung krakvitz,
                         ferienwohnung ostsee, ostsee, personen, sommer, strand, ferienwohnung mieten, krakvitz, putbus, ferienwohnung putbus, ferienwohnung kinder, kinder,
                         ferienwohnung mieten rügen, ferienwohnung mieten putbus, ferienwohnung mieten ostsee, kinderfreundlich, fewo rügen, fewo ostsee, fewo putbus, fewo krakvitz, fewo,
                         ökologisch, naturnah, Vilm, Ökoferien, Ökourlaub, Nichtraucher, Ökourlaub Ostsee, Ferienhaus, Ferienhaus Ostsee, Ferienhaus Rügen, Bio Urlaub"
                />
                <link rel="canonical" href="https://xn--waschhaus-rgen-qsb.de/preiseundbelegung" />

            </Helmet>

            <ThemeProvider theme={theme}>

            <main className={classes.main1}>
                    <NavbarPreiseundBelgungsplan/>

                <Container className={classes.main1} disableGutters={true} maxWidth="md">
                    <Container disableGutters={true}>
                        <Typography variant="h2" component="h2" gutterBottom className={classes.header} align="center" marginTop={5}>
                        Preise {/*und Belegungsplan*/}
                        </Typography>
                    </Container>

                    <Preise/>

                    <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body1" gutterBottom className={classes.text1} align="center">
                                Für alle Angebote gilt: Bettwäsche und Handtücher sind für alle Gäste inklusive.
                                <br/>
                                <br/>
                                Zusätzlich berechnen wir eine Endreinigung in Höhe von 65€ und sammeln im Namen der Stadt Putbus die örtliche Kurabgabe ein.
                                Handtuchwechsel, die Bereitstellung von Kaminholz, sowie die Nutzung von Waschmaschine und Trockner erfolgen gegen eine geringe Gebühr.
                                <br/>
                                <br/>
                                In der Hauptsaison beträgt der Mindestaufenthalt 7 Tage. Die An- und Abreise erfolgt in der Regel samstags.
                                <br/>
                            </Typography>
                        </CardContent>                                <br/>


                    </Container>

                    <br/>                                <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <br/>
                    <br/>
                    <br/>

                    {/* <Belegungsplan/> */}
                </Container>
                </main>
            <Footer/>
            </ThemeProvider>

        </React.Fragment>
    );
}
