import React, {useState} from 'react';

import { Button } from 'styled-button-component';
import { Navbar, NavbarLink } from 'styled-navbar-component';
import { Nav } from 'styled-nav-component';

export const NavbarVilm = () => {
    const [hidden, setHidden] = useState(true);

    return (
        <Navbar expandSm dark>
            <Nav start>
                <NavbarLink dark brand href="/">ALTES WASCHHAUS RÜGEN</NavbarLink>
                <Nav end>
                    <Button
                        light
                        outline
                        toggleCollapse
                        expandSm
                        onClick={() => setHidden(!hidden)}
                    >
                        <span>&#9776;</span>
                    </Button>
                </Nav>
            </Nav>
            <Nav start collapse expandSm hidden={hidden}>
                <NavbarLink dark href="/">Home</NavbarLink>
                <NavbarLink dark active>Fewo "Vilm"</NavbarLink>
                <NavbarLink dark href="/preiseundbelegung">Preise</NavbarLink>
                <NavbarLink dark href="/aktivitaeten">Aktivitäten</NavbarLink>
                <NavbarLink dark href="/kontakt">Kontakt</NavbarLink>
            </Nav>
        </Navbar>
    );
};
