import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({

    footer: {
        padding: theme.spacing(2, 2),
        marginTop: 'auto',
        backgroundColor: '#343A40',
    },
    text:{
        color: "#fff",
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <footer className={classes.footer}>
                <Container maxWidth="md">
                    <Typography variant="body2" color="textSecondary" align="center" className={classes.text}>
                        {'Copyright © Altes Waschhaus Krakvitz'}{' '}
                        {new Date().getFullYear()}
                        {'.'}{' '}
                        <Link color="inherit" href="/impressum">
                            Impressum
                        </Link>
                        {'.'}{' '}
                        <Link color="inherit" href="datenschutzerklaerung">
                            Datenschutzerklärung
                        </Link>{'.'}
                    </Typography>
                </Container>
            </footer>
        </div>
    );
}
