import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {NavbarDatenschutz} from "./NavbarDatenschutz";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "../Footer/Footer";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
    },
    cardTextGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#434A7E',
    },
    main2: {
        backgroundColor: '#e3e6ff',
    },
    footer: {
        backgroundColor: '#343A40',
        marginTop: theme.spacing(8),
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",
    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    }
}));

export default function Impressum() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ferienwohnungen Waschhaus Rügen - Impressum </title>
                <meta
                    name="description"
                    content=
                        "Ökologische Ferien auf Rügen: In unseren Ferienwohnungen Altes Waschhaus und Vilm im Dorf Krakvitz auf Rügen.
                        Genießen sie einen ruhigen Urlaub an der Ostsee. In der Natur und fernab vom Massentourismus in unseren Ferienwohnungen auf Rügen.
                        Perfekt für Erwachsene und Familien mit Kindern."
                />
                <meta
                    name="keywords"
                    content=
                        "impressum"
                />
                <link rel="canonical" href="https://xn--waschhaus-rgen-qsb.de/preiseundbelegung" />

            </Helmet>

            <ThemeProvider theme={theme}>

                <main className={classes.main1}>
                    <NavbarDatenschutz/>

                    <Container disableGutters={true}>
                        <Typography variant="h2" component="h2" gutterBottom className={classes.header} align="center" marginTop={5}>
                            Impressum
                        </Typography>
                    </Container>

                    <Container className={classes.main2} maxWidth="lg" disableGutters={true}>
                        <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Angaben gemäß § 5 TMG
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Katja und Dr. Alexander Grossmann <br/>
                                    Krakvitz 14B <br/>
                                    18581 Putbus <br/>
                                </Typography>


                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Kontakt
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    E-Mail: info@waschhaus-ruegen.de  <br/>
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Haftung für Inhalte                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Haftung für Links
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                                    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                                    waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                                    umgehend entfernen.
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Urheberrecht
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                    <br/><br/>
                                    Quelle:
                                    https://www.e-recht24.de
                                </Typography>
                            </CardContent>
                        </Container>
                    </Container>
                </main>
            <Footer/>
            </ThemeProvider>
        </React.Fragment>
    );
}
