import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Home/Home";
import FewoVilm from "./Fewo Vilm/FewoVilm";
import Aktivitaeten from "./Aktivitäten/Aktivitaeten";
import Buchungsanfrage from "./Kontakt/Buchungsanfrage";
import BuchungsfrageErfolgreich from "./Kontakt/BuchungsanfrageErfolgreich";
import PreiseUndBelegungplan from "./Preise & Belegnungsplan/Preise & Belegungsplan";
import Datenschutz from "./Footer/Datenschutz";
import Impressum from "./Footer/Impressum";
import * as firebase from 'firebase';


const firebaseConfig = {
    apiKey: "AIzaSyCVB9HbtcQIEXDF4IlMaQ4itO1zD94PMQA",
    authDomain: "krakvitz.firebaseapp.com",
    databaseURL: "https://krakvitz.firebaseio.com",
    projectId: "krakvitz",
    storageBucket: "krakvitz.appspot.com",
    messagingSenderId: "393248198705",
    appId: "1:393248198705:web:0b80a4c56591586fe0c510",
    //    measurementId: "G-B6ZZ36B22M"
};
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

ReactDOM.render(
  <React.StrictMode>

      <Router>
          <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/fewo-vilm" component={FewoVilm}/>
              <Route exact path="/aktivitaeten" component={Aktivitaeten}/>
              <Route exact path="/preiseundbelegung" component={PreiseUndBelegungplan}/>
              <Route exact path="/kontakt" component={Buchungsanfrage}/>
              <Route exact path="/buchungerfolgreich" component={BuchungsfrageErfolgreich}/>
              <Route exact path="/datenschutzerklaerung" component={Datenschutz}/>
              <Route exact path="/impressum" component={Impressum}/>
          </Switch>
      </Router>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
