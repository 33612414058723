import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CssBaseline from "@material-ui/core/CssBaseline";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#2196F3",
        color: theme.palette.common.white,
        borderBottom: 0,
        fontSize: 16,
    },
    head2: {
        backgroundColor: "#2196F3",
        color: theme.palette.common.white,
        borderBottom: 0,
        fontSize: 16,
    },
    body: {
        fontSize: 16,
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: '#434A7E',
    },



}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(fewo, neben, vornach, haupt) {
    return { fewo, neben, vornach, haupt};
}

const rows = [
    createData('Ferienwohnung Vilm (1-2 Personen)', 88, 108, 118),
    createData('Ferienwohnung Vilm (3-4 Personen)', 88, 118, 128),
];

const useStyles = makeStyles({
    table: {
        maxWidth: "100%",
    },
});

export default function Preise() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
        <TableContainer component={Paper} justify="center" align="center">
            <Table className={classes.table} aria-label="customized table" >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Saison</StyledTableCell>
                        <StyledTableCell align="center">Nebensaison</StyledTableCell>
                        <StyledTableCell align="center">Vor-/Nachsaison</StyledTableCell>
                        <StyledTableCell align="center">Hauptsaison</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Zeitraum</StyledTableCell>
                        <StyledTableCell align="center">(04.01. - 30.03. &<br/> 01.11 - 25.12.)</StyledTableCell>
                        <StyledTableCell align="center">(01.04. - 31.05. &<br/> 01.10 - 31.10.)</StyledTableCell>
                        <StyledTableCell align="center">(01.06. - 30.09. &<br/> 26.12. - 03.01.)</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.fewo}>
                            <StyledTableCell component="th" scope="row">
                                {row.fewo}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.neben}€</StyledTableCell>
                            <StyledTableCell align="center">{row.vornach}€</StyledTableCell>
                            <StyledTableCell align="center">{row.haupt}€</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </React.Fragment>
    );
}
