import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {NavbarKontakt} from "./NavbarKontakt";
import green from "@material-ui/core/colors/green";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },

    main1: {
        backgroundColor: '#434A7E',
        marginBottom: theme.spacing(2),
    },
    main2: {
        backgroundColor: '#e3e6ff',
        marginBottom: theme.spacing(2),
    },
    footer: {
        backgroundColor: '#343A40',
        marginTop: theme.spacing(8),
    },
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(7),
        color: "#fff",
        fontWeight: "bold",
    },
    text1: {
        color: "#434A7E",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    }
}));

export default function BuchungsfrageErfolgreich() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>

                <main className={classes.main1}>
                <NavbarKontakt/>

                    <Container disableGutters={true} maxWidth="lg">
                        <Typography variant="h2" component="h1" gutterBottom className={classes.header} align="center" marginTop={5}>
                            Buchungsanfrage
                        </Typography>
                    </Container>

                    <Container disableGutters={true} maxWidth="md" align="center">

                    <CheckCircleIcon style={{ color: green[500], fontSize: 350 }}/>

                    </Container>

                    <Container disableGutters={true} maxWidth="lg">
                        <Typography variant="h5" component="h5" gutterBottom className={classes.text2} align="center" marginTop={5}>
                            Vielen Dank! Wir setzen uns so schnell wie möglich mit Ihnen in Verbindung.
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                        </Typography>
                    </Container>

            </main>

            </ThemeProvider>
        </React.Fragment>
    );
}
