import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: '#434A7E'
    },
    gridList: {
        width: 1000,
        height: 1800,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

  const tileData = [
    {
      img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Binz_%28R%C3%BCgen%29_-_Kleinbahn-Bahnhof_%281%29_%2811707274224%29.jpg/1280px-Binz_%28R%C3%BCgen%29_-_Kleinbahn-Bahnhof_%281%29_%2811707274224%29.jpg',
        title: 'Rasender Roland',
        author: '10 Autominuten',
        link: "http://ruegensche-baederbahn.de/home.html",
    },
    {
        img: 'https://www.knauscamp.de/typo3temp/pics/699af85477.jpg',
        title: 'Nationalpark Köngigsstuhl',
        author: '50 Autominuten',
    },
      {
          img: 'https://tpl.mmcdn.de/1006_pc4420/images/bildleiste/putbus-circus-putbus.jpg',
          title: 'Fürstenstadt Putbus',
          author: '10 Autominuten',
      },
      {
          img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Jagdschloss_Granitz_4.jpg/1280px-Jagdschloss_Granitz_4.jpg',
          title: 'Jagdschloss Granitz',
          author: '30 Autominuten',
      },
      {
          img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Stralsund%2C_Blick_von_der_Marienkirche_%282013-07-07-%29%2C_by_Klugschnacker_in_Wikipedia_%287%29.JPG/1280px-Stralsund%2C_Blick_von_der_Marienkirche_%282013-07-07-%29%2C_by_Klugschnacker_in_Wikipedia_%287%29.JPG',
          title: 'Hansestadt Stralsund',
          author: '30 Minuten entfernt',
      },
      {
          img: 'https://www.ostseeurlaub-online.de/binz/ostsee-urlaub-binz-ruegen.jpg',
          title: 'Ostseebad Binz',
          author: '25 Autominuten',
          link: '"https://www.ostseebad-binz.de/binz-im-fruehling/"',
      },
      {
          img: 'https://www.baumwipfelpfad-baumkronenpfad.de/media/images/baumwipfelpfad-ruegen-800x534.jpg',
          title: 'Baumwipfelpfad',
          author: '25 Autominuten',
          link: '"https://www.baumwipfelpfade.de/nezr/"',
      },
      {
          img: 'https://krakvitz.firebaseapp.com/images/rügen/RügenA.jpg',
          title: 'Naturstrand Bodden',
          author: '20 Fußminuten',
          link: '"https://www.google.de/maps/place/Prussia+Columns/@54.3188806,13.4620296,18z/data=!4m5!3m4!1s0x47ab6ba2af4b7d5d:0x592056661ec6092e!8m2!3d54.3189318!4d13.4618381"',
      },
      {
          img: 'https://www.marepublica.de/wp-content/uploads/2017/03/Bauernmarkt-Eingang_3.jpeg',
          title: 'Karls Erdbeerhof',
          author: '20 Autominuten',
          link: '"https://www.karls.de/zirkow.html"',
      },
      {
          img: 'https://www.meine-ferienregion.de/sites/default/files/styles/450x300/public/indoor-playground/7287bca445a461f86389700c9ab3eb5e/Erlebniswelt-SPLASH_1572698692_1.jpg?itok=TQDSwJ1R',
          title: 'Pirateninsel Putbus',
          author: '10 Autominuten',
          link: '"https://www.pirateninsel-ruegen.de/"',
      },
      {
          img: 'https://www.ostseebad-sellin.de/wp-content/uploads/2016/11/Seebruecke-Sellin-Sterne_Foto-Pocha-Burwitz.jpg',
          title: 'Seebrücke Sellin',
          author: '30 Autominuten',
          link: '"https://www.ostseebad-sellin.de/"',
      },
      {
          img: 'https://krakvitz.firebaseapp.com/images/rügen/Fahrrad.jpg',
          title: 'Fahrradtouren',
          author: 'Einen Schritt',
          link: '"http://www.fahrradverleih-ruegen.de/"',
      },
      {
          img: 'https://krakvitz.firebaseapp.com/images/rügen/RügenB.jpg',
          title: 'Wandern',
          author: 'Einen Schritt',
          link: '"https://www.ruegen.de/aktiv-und-natur/wandern"',
      },
      {
          img: 'https://deutschland-outdoor.de/fileadmin/_processed_/b/1/csm_Lauterbach-Hafen_dd7c0f31a1.jpg',
          title: 'Lauterbach Hafen',
          author: '10 Autominuten',
          link: '"https://de.wikipedia.org/wiki/Lauterbach_(R%C3%BCgen)"',
      },
  ];
 export default function TitlebarGridList() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <GridList cellHeight={250} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                </GridListTile>
                {tileData.map((tile) => (
                    <GridListTile key={tile.img}>
                        <img src={tile.img} alt={tile.title} />
                        <GridListTileBar
                            title={tile.title}
                            subtitle={<span>{tile.author} entfernt</span>}
                            actionIcon={
                                <IconButton link={"https://www.ostseebad-binz.de/binz-im-fruehling/"}  className={classes.icon}>
                                    <InfoIcon link={tile.link} className={classes.icon}/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}
