import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import VilmBookingCalendar from "../Fewo Vilm/VilmBookingCalendar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import {indigo} from "@material-ui/core/colors";

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#434A7E",
        '&:hover': {
            backgroundColor: indigo[200],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
        },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
    root: {
        color: "#434A7E",
        backgroundColor: "#fff",
        '&:hover': {
            backgroundColor: indigo[200],
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    heroButtons: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },

    main: {
        backgroundColor: '#434A7E',
    },
    header: {
        background: '#e3e6ff',
    },
    h1: {
        fontSize: '72px',
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
        fontWeight: 'bold',
    },
    h2: {
        fontSize: '48px',
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
    h3: {
        fontSize: '36px',
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
    h4: {
        fontSize: '24px',
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
    cardh3: {
        fontSize: '36px',
        color: "#000000",
        fontFamily: '"Segoe UI Emoji"',
    },
    cardh4: {
        fontSize: '24px',
        color: "#000000",
        fontFamily: '"Segoe UI Emoji"',
    },
    p: {
        fontSize: '14px',
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
}));

export default function FewoWaschhaus() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.main}>
                <Container className={classes.cardGrid} maxWidth="lg" disableGutters={true}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2" align="center">
                                        Buchungskalender "Vilm"
                                    </Typography>
                                </CardContent>
                                <VilmBookingCalendar/>
                                <div className={classes.heroButtons}>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <ButtonGroup enableElevation  variant="contained" size="large" >
                                                <ColorButton2 href="/fewo-vilm">
                                                    Mehr Informationen
                                                </ColorButton2>
                                                <ColorButton href="/kontakt" >
                                                    Buchungsanfrage
                                                </ColorButton>
                                            </ButtonGroup>

                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
}

